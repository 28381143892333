const colors = {
  white: 'white',
  earlyBirdPurple: '#7649a3',
  lavenderGray: '#b6b5d8',
  ironGray: '#616569',
  indigo: '#4b0082',
  orange: '#f79c30',
  cantaloupe: '#fda172',
  blackPearl: '#151e27',
  infoBlock: '#00549d',
  infoBorder: '#89b9e8',
  silverChalice: '#A9A9A9',
};

export const darkTheme = {
  colors: {
    background: '#141d26', // '#0e0f18',
    primary: colors.orange,
    strongText: colors.white,
    homeName: colors.orange,
    text: colors.white,
    appBar: '#31304D', // '#24225d',
    chip: colors.cantaloupe,
    experienceSummary: '#31304D', //, '#24225d',
    experienceSummaryHover: '#5F5E84',
    experienceDetails: "#7B2CBF",
    infoBlock: colors.infoBlock,
    infoBorder: colors.infoBorder,
    link: colors.silverChalice,
    infoCross: colors.silverChalice,
  },
  fonts: {
    h1: '3em',
    h2: '1em',
    h3: '1em',
    homeAppBarLink: '1.2em',
    homePresentation: '2em',
    homePresentationSpecialization: '1em',
    homeContactLink: '1.5em',
    aboutDescription: '1.2em',
    skillBoxTitle: '1.2em',
    experienceTitle: '1.2em',
    experienceLocationWebsite: '1em',
    experienceDescription: '1em',
    experienceSkillChip: '0.8em',
    contactText: '1.5em',
  }
};
